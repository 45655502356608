<template>
	<v-container class="puiFilterGroup-custom pa-0 mx-1">
		<dynamic-form-control-group
			v-if="columns.length > 0"
			ref="input0"
			:columnsProp="columns"
			:filterColumnValues="filterColumnValues"
			:filterGroupProp="filterModel"
			:disabled="disabled"
			:showSubgroupBtn="showSubgroupBtn"
			isRootGroup
			@change="onFilterGroupChanges"
			:maxFilters="maxFilters"
		></dynamic-form-control-group>
	</v-container>
</template>

<script>
//import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import DynamicFormControlGroup from '../dynamic_form_control/DynamicFormControlGroup.vue';

export default {
	name: 'DynamicFormControl',
	//mixins: [PuiFormMethodsMixin],
	components: {
		DynamicFormControlGroup
	},
	data() {
		return {
			filterModel: null,
			modelName: 'pmareadefinition',
			modelDependencyName: 'pmareadefinition',
			savingMode: false,
			primaryColor: this.$store.state.global.primaryColor
		};
	},
	props: {
		filterProp: {
			type: Array,
			required: true
		},
		columns: {
			type: Array,
			required: true
		},
		filterColumnValues: {
			type: Object,
			required: false
		},
		disabled: {
			type: Boolean,
			required: true
		},
		maxFilters: {
			type: Number,
			required: true
		},
		showSubgroupBtn: {
			type: Boolean,
			default: false
		}
		/* modelData: {
			type: Object,
			required: true,
			default() {
				return { message: 'hello' };
			}
		} */
	},
	watch: {
		filterProp() {
			this.filterModel = this.filterProp;
		},
		filterModel: {
			deep: true,
			handler(val, prev) {
				console.info('filterModel watcher', prev, val);
				this.$emit('change', this.filterModel);
			}
		}
	},
	created() {
		this.filterModel = this.filterProp;
	},
	mounted() {
		console.log('mounted dynamic form control', this.filterProp, this.maxFilters, this.columns);
	},
	methods: {
		onFilterGroupChanges() {
			this.filterModel = this.$refs.input0.filterGroupModel;
			//console.log('onFilterGroupChanges: filterModel', this.filterModel, this.$parent);
		}
		/* checkFilterNameBeforeSave() {
			this.method = 'create';
			this.model = this.modelData;
			console.debug('debug session & functionalities',  this.$store.getters.getSession,  this.$store.getters.getSession.functionalities);
			if (this.model.pmorganizationid && this.model.filter && this.model.model) {
				this.save(true);
				return true;
			}
			return false;
		} */
	}
};
</script>
