<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row wrap class="pui-form-layout">
				<pui-field-set :title="$t('pmarea.title')">
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-text-field :label="$t('pmarea.name')" v-model="model.name" required toplabel maxlength="150"></pui-text-field>
						</v-flex>
						<v-flex xs12 md4>
							<pui-text-field v-model="model.acronym" :label="$t('pmarea.acronym')" maxlength="5" required toplabel></pui-text-field>
						</v-flex>
						<v-flex xs12 md4>
							<pui-select
								v-if="isSuperAdmin"
								attach="pmorganizationid"
								:label="this.$t('pmarea.pmorganization')"
								:placeholder="this.$t('pmarea.phorganization')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="vluppmorganization"
								:modelFormMapping="{ pmorganizationid: 'pmorganizationid' }"
								:itemsToSelect="organizationItemsToSelect"
								itemValue="pmorganizationid"
								itemText="name"
							></pui-select>
							<pui-text-field
								v-else
								:label="$t('pmarea.pmorganization')"
								toplabel
								disabled
								required
								v-model="organizationName"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md6>
							<pui-text-field v-model="model.image" :label="$t('pmarea.image')" toplabel></pui-text-field>
						</v-flex>
						<v-flex xs12 md6>
							<pui-select
								attach="pmareatypeid"
								:label="this.$t('pmarea.pmareatype')"
								:placeholder="this.$t('pmarea.phareatype')"
								toplabel
								clearable
								required
								reactive
								:disabled="formDisabled || this.model.pmorganizationid == null"
								v-model="model"
								modelName="vluppmareatype"
								:modelFormMapping="{ pmareatypeid: 'pmareatypeid' }"
								:itemsToSelect="areatypeItemsToSelect"
								itemValue="pmareatypeid"
								itemText="name"
								filterId="pmorganizationid"
								:filterMap="{ pmorganizationid: model.pmorganizationid }"
								:filterParentMap="{ pmorganizationid: 'id' }"
							></pui-select>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12>
							<pui-text-area v-model="model.description" :label="$t('pmarea.description')" maxlength="250" toplabel></pui-text-area>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-checkbox
								:label="$t('pmarea.disabled')"
								v-model="model.disabled"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
					</v-layout>
					<pui-field-set :title="$t('pmarea.location')">
						<dynamic-form-control
							ref="areaFormFilter"
							:filterProp="areaDefinition"
							:columns="dynamicColumns"
							:disabled="formDisabled"
							:maxFilters="maxFilters"
							:showSubgroupBtn="false"
						></dynamic-form-control>
					</pui-field-set>
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="validateArea"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

import { isSuperAdmin } from '@/api/common';
import DynamicFormControl from '../form/dynamic_form_control/DynamicFormControl';

export default {
	name: 'pmareaform',
	mixins: [PuiFormMethodsMixin],
	components: { DynamicFormControl },
	data() {
		return {
			modelName: 'pmarea',
			model: 'pmarea',
			isSuperAdmin: false,
			maxFilters: 5,
			areaDefinition: [[0, 0]],
			dynamicColumns: [
				{ label: this.$t('pmarea.lat'), min: -90, max: 90 },
				{ label: this.$t('pmarea.lon'), min: -180, max: 180 }
			]
		};
	},
	computed: {
		areatypeItemsToSelect() {
			return [{ pmareatypeid: this.model.pmareatypeid }];
		},
		organizationItemsToSelect() {
			return [{ pmorganizationid: this.model.pmorganizationid }];
		},
		organizationName() {
			return this.userProperties['organizationname'];
		},
		isUpdateEntry() {
			return typeof this.model == 'object' && !!this.model.pmareaid;
		}
	},
	watch: {
		'model.pmorganizationid': function (oldValue, newValue) {
			if (this.model.pmorganizationid == null || (oldValue != newValue && newValue != null)) {
				this.model.pmareatypeid = null;
			}
		},
		'model.pmareadefinitionid': {
			handler(val, prev) {
				console.debug('info: model.pmareadefinitionid watcher', prev, val, !!this.model.filter);
				if (this.isUpdateEntry && !prev && val) {
					let areaDefinition = this.getAreaDefinition(val);
					console.log('debug area definition', areaDefinition);
					if (areaDefinition) this.areaDefinition = JSON.parse(areaDefinition.areapoints);
				}
			}
		}
	},
	beforeCreate() {
		if (this.model) {
			this.modelLoaded = true;

			this.model.pmorganizationid = this.userProperties['organizationid'];
		}
	},
	mounted() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
	},

	updated() {
		if (this.model) {
			if (!this.isSuperAdmin) {
				this.model.pmorganizationid = this.userProperties['organizationid'];
			}
		}
		console.log('this model', this.model);
	},
	methods: {
		/* async reloadStore() {
			if (this.model.pmareaid !== null) {
				//Se trata de una actualización
				if (this.$refs.form && this.$refs.form.validate && this.$refs.form.validate()) {
					var areas = this.$store.getters.getAreas;

					areas.forEach((item) => {
						console.log('ITEM');
						if (item.pmareaid === this.model.pmareaid) {
							item.disabled = this.model.disabled;
							//return false; //equivalent to break and doesn't works!
						}
					});
					this.$store.commit('setAreas', areas);
					console.log('AREAS: ', areas);
				}
				this.save();
			} else {
				this.save();
				console.log('Retrieve number of areas');
				await getAreas(this.session.language, this.userProperties);
			}
		}, */
		async validateArea() {
			console.log('validate area', this.areaDefinition, this.$refs.form.validate());
			if (this.$refs.form && this.$refs.form.validate && !this.$refs.form.validate()) {
				this.formValidationErrors = true;
				this.$puiNotify.error(this.$t('pui9.error.form_errors'));
				this.resetAfterSaveActions();
				return;
			}
			!this.model.pmareadefinitionid
				? this.insertAreaDefinitionAndSave(JSON.stringify(this.areaDefinition))
				: this.updateAreaDefinitionAndSave(JSON.stringify(this.areaDefinition));
		},
		async insertAreaDefinitionAndSave(areaDefinition, shouldStayInPage = true) {
			let url = this.$store.getters.getModelByName('pmareadefinition').url.insert;
			let filterModel = {
				pmorganizationid: this.model.pmorganizationid,
				areapoints: areaDefinition,
				disabled: 0
			};
			this.$puiRequests.postRequest(
				url,
				filterModel,
				(response) => {
					console.debug('filter insert response', response.data);
					if (response.data.pmareadefinitionid) {
						this.model.pmareadefinitionid = response.data.pmareadefinitionid;
					}
					if (!(shouldStayInPage === true)) {
						if (this.internal.afterInsertGoToUpdate) {
							this.goToUpdate(response.data);
						} else if (this.internal.afterInsertGoToCreate) {
							// Router not allow to go same router with same parameters
							this.refreshRouter();
						} else {
							this.back();
						}
						this.resetAfterSaveActions();
					}
					console.log('saved!', this.model, this.model.pmareadefinitionid);
					this.save(false);
				},
				(error) => {
					this.resetAfterSaveActions();
					this.saving = false;
					this.onSaveError && this.onSaveError();
					this.onError(error);
				}
			);
		},
		async updateAreaDefinitionAndSave(areaDefinition, shouldStayInPage = true) {
			let url = this.$store.getters.getModelByName('pmareadefinition').url.update;
			let filterModel = {
				pmareadefinitionid: this.model.pmareadefinitionid,
				pmorganizationid: this.model.pmorganizationid,
				areapoints: areaDefinition,
				disabled: 0
			};

			this.$puiRequests.putRequest(
				url,
				filterModel,
				(response) => {
					console.debug('filter update response', response.data);
					if (!(shouldStayInPage === true)) {
						if (this.internal.afterInsertGoToUpdate) {
							this.goToUpdate(response.data);
						} else if (this.internal.afterInsertGoToCreate) {
							// Router not allow to go same router with same parameters
							this.refreshRouter();
						} else {
							this.back();
						}
						this.resetAfterSaveActions();
					}
					console.log('saved!', this.model, this.model.pmareadefinitionid);
					this.save(false);
				},
				(error) => {
					this.resetAfterSaveActions();
					this.saving = false;
					this.onSaveError && this.onSaveError();
					this.onError(error);
				}
			);
		},
		getAreaDefinition(areaDefinitionId) {
			return this.$store.state.form.areaDefinitions.find((areaDef) => areaDef.pmareadefinitionid == areaDefinitionId);
		}
	}
};
</script>
