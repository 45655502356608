<template>
	<v-container class="pa-2 mb-2 puiFilterGroup-custom" @mouseover.stop="showGroupBtns = true" @mouseout="showGroupBtns = false">
		<!-- Selects reglas -->
		<div v-if="filterGroupModel && filterGroupModel.length > 0">
			<v-row no-gutters v-for="(rule, index) in filterGroupModel" :key="index" class="mb-2">
				<v-col cols="12" class="pt-0 pa-0 pl-0 pr-2 d-flex align-center">
					<dynamic-form-control-input
						:ref="`rule${index}`"
						:ruleIndexProp="index"
						:groupName="groupName"
						:columnsProp="columnsProp"
						:filterColumnValues="filterColumnValues"
						:filterRuleProp="rule"
						:disabled="disabled"
						@change="onFilterRuleChanges(index)"
					></dynamic-form-control-input>
					<v-btn
						icon
						v-if="!disabled"
						@click="removeRule(index)"
						:disabled="filterGroupModel.length == 1"
						class="d-flex justify-center align-center mb-2"
					>
						<v-icon class="delete-btn" small :ripple="false">fa fa-trash-alt</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</div>
		<!-- <div v-if="filterGroupModel && filterGroupModel.length > 0">
			<v-layout class="mb-2">
				<v-flex xs12 class="pt-2 pa-0 pl-0 pr-2 d-flex align-center">
					<dynamic-form-control-input
						ref="rule0"
						:ruleIndexProp="0"
						:groupName="groupName"
						:columnsProp="columnsProp"
						:filterColumnValues="filterColumnValues"
						:filterRuleProp="filterGroupModel[0]"
						:disabled="disabled"
					></dynamic-form-control-input>
					<v-icon
						v-if="disabled === false"
						class="ml-2 delete-btn"
						small
						:ripple="false"
						:disabled="filterGroupModel.length == 1"
						@click="removeRule(index)"
						>fa fa-trash-alt</v-icon
					>
				</v-flex>
			</v-layout>
		</div> -->

		<v-col cols="12" class="elevation-0" :class="isRootGroup && filterGroupModel.length > 0 ? 'pa-0 pt-0' : 'pa-0 pt-3'">
			<v-btn v-if="!disabled" class="btnGroup btnAddRule elevation-0" @click="addRule" :disabled="filterGroupModel.length >= maxFilters">
				<v-icon small>far fa-plus</v-icon>
				<span>{{ $t('pmarea.addPoint') }}</span>
			</v-btn>
		</v-col>
	</v-container>
</template>

<script>
import DynamicFormControlInput from './DynamicFormControlInput.vue';

export default {
	name: 'puiFilterGroup-custom',
	components: {
		DynamicFormControlInput
	},
	props: {
		isRootGroup: {
			type: Boolean,
			default: false
		},
		groupIndexProp: {
			type: Number,
			default: 0
		},
		filterGroupProp: {
			required: true
		},
		columnsProp: {
			type: Array,
			required: true
		},
		filterColumnValues: {
			type: Object,
			required: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		maxFilters: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			filterGroupModel: this.getDefaultFilterGroupModel(),
			showDeleteRule: null,
			showGroupBtns: null
		};
	},
	computed: {
		groupName() {
			return this.isRootGroup ? this.groupIndexProp.toString() : this.$parent.groupName + '-' + this.groupIndexProp;
		}
	},
	watch: {
		filterGroupProp() {
			if (this.filterGroupProp) {
				this.filterGroupModel = this.filterGroupProp;
			}
		},
		filterGroupModel: {
			deep: true,
			handler() {
				console.info('filterGroupModel', this.filterGroupModel);
				this.$emit('change', this.filterGroupModel);
			}
		}
	},
	created() {
		if (this.filterGroupProp) {
			this.filterGroupModel = this.filterGroupProp;
		}
	},
	mounted() {
		/* if (this.filterGroupModel.length === 0) {
			this.addRule();
		} TODO: UNDO */
	},
	methods: {
		onFilterGroupChanges(index) {
			this.filterGroupModel = this.$refs[`group${index}`][0].filterGroupModel;
		},
		onFilterRuleChanges(index) {
			this.filterGroupModel[index] = this.$refs[`rule${index}`][0].filterRuleModel;
		},
		addRule() {
			if (this.filterGroupModel.length <= this.maxFilters - 1) {
				this.filterGroupModel.push(DynamicFormControlInput.methods.getDefaultFilterRuleModel());
			}
		},
		removeRule(index) {
			this.filterGroupModel.splice(index, 1);
		},
		getDefaultFilterGroupModel() {
			return [[0, 0]];
		}
	}
};
</script>

<style lang="postcss" scoped>
.btnGroup {
	height: 28px !important;
	color: var(--N-500);
	& span {
		padding-left: 6px;
	}
}
.delete-btn {
	min-height: 100% !important;
}
.puiFilterGroup-custom {
	background-color: var(--N-0);
	border-color: var(--N-100);
	border-style: solid;
	border-radius: 5px;
	border-width: 1px;

	&__groupOperator {
		height: 50px;
	}
	&__bottombar {
		background-color: transparent !important;
	}
	& .puiFilterGroup-custom {
		background-color: var(--N-10);
		& .v-btn {
			background-color: var(--N-10);
		}
		& .puiFilterGroup-custom {
			background-color: var(--N-0);
			& .v-btn {
				background-color: var(--N-0);
			}
			& .puiFilterGroup-custom {
				background-color: var(--N-10);
				& .v-btn {
					background-color: var(--N-10);
				}
				& .puiFilterGroup-custom {
					background-color: var(--N-0);
					& .v-btn {
						background-color: var(--N-0);
					}
					& .puiFilterGroup-custom {
						background-color: var(--N-10);
						& .v-btn {
							background-color: var(--N-10);
						}
						& .puiFilterGroup-custom {
							background-color: var(--N-0);
							& .v-btn {
								background-color: var(--N-0);
							}
						}
					}
				}
			}
		}
	}
}
</style>
