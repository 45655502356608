<template>
	<v-row no-gutters class="DynamicFormControlInput">
		<v-col cols="12">
			<v-row no-gutters>
				<v-col cols="12" sm="6">
					<pui-number-field
						v-model="value0"
						:label="columnsProp[0].label"
						:min="columnsProp[0].min"
						:max="columnsProp[0].max"
						toplabel
						class="pr-1"
					></pui-number-field>
				</v-col>
				<v-col cols="12" sm="6">
					<pui-number-field
						class=""
						:label="columnsProp[1].label"
						:min="columnsProp[1].min"
						:max="columnsProp[1].max"
						v-model="value1"
						toplabel
						:disabled="disabled"
					></pui-number-field>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'DynamicFormControlInput',
	mixins: [PuiFormMethodsMixin],
	props: {
		groupName: {
			type: String
		},
		ruleIndexProp: {
			type: Number
		},
		filterRuleProp: {
			type: Array
		},
		columnsProp: {
			type: Array,
			required: true
		},
		filterColumnValues: {
			type: Object,
			required: false
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			filterRuleModel: this.getDefaultFilterRuleModel(),
			column: {
				type: '',
				name: '',
				title: ''
			},
			value0: null,
			value1: null,
			emptyDisabled: false
		};
	},
	computed: {},
	watch: {
		filterRuleProp() {
			this.filterRuleModel = this.filterRuleProp;
			this.setValues();
		},

		value0: {
			// eslint-disable-next-line no-unused-vars
			handler(newValue, oldValue) {
				//para el caso de between o not between
				console.info('value0 from', oldValue, 'to ', newValue);

				this.filterRuleModel = [];
				this.filterRuleModel = [newValue, this.value1 ?? 0];
			},
			deep: true
		},
		value1: {
			// eslint-disable-next-line no-unused-vars
			handler(newValue, oldValue) {
				console.info('value1 from', oldValue, 'to ', newValue);
				//let tempFilter = this.filterRuleModel;

				this.filterRuleModel = [];
				this.filterRuleModel = [this.value0 ?? 0, newValue];

				//this.filterRuleModel = tempFilter;
			},
			deep: true
		},
		filterRuleModel: {
			// eslint-disable-next-line no-unused-vars
			handler(newValue, oldValue) {
				console.info('filterRuleModel from', oldValue, 'to ', newValue);
				this.$emit('change', this.filterRuleModel);
			},
			deep: true
		}
	},
	created() {
		this.filterRuleModel = this.filterRuleProp;
		this.setValues();
	},
	methods: {
		setValues() {
			if (Array.isArray(this.filterRuleModel)) {
				this.value0 = this.filterRuleModel[0];
				this.value1 = this.filterRuleModel[1];
			}
		},
		clearValues() {
			this.filterRuleModel = null;
			this.value0 = null;
			this.value1 = null;
		},
		getDefaultFilterRuleModel() {
			return [0, 0];
		}
	}
};
</script>

<style lang="postcss"></style>
